.farfetch {
  transform: scale(1.5);
}
.defiance {
  transform: scale(1.25);
}
.ibm {
  transform: scale(.6);
}
.amyris {
  transform: scale(.9);
}
.biossance {
  transform: scale(.9);
}